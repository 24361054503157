<template>
  <section class="CalendarSection">
    <div
      style="
        display: flex;
        justify-content: space-between;
        border: 0px solid lightgray;
        margin-bottom: 30px;
      "
    >

      <b-modal
        ref="modal"
        v-model="showModal"
        :title="$t('Confirmation')"
        header-class="headerModal"
        centered
        hide-footer
      >
        <div>{{ $t('SaturdayConfig') }}</div>

        <div>
          <div class="buttonsEverywhere">
            <b-button
              type="button"
              variant="primary"
              class="buttonSubmit"
              style="
                background:
                  linear-gradient(52deg, #262e6c 0%, #9b2a5c 100%, #ff274f 100%) 0% 0% no-repeat
                  padding-box;
                margin-top: 10px;
              "
              @click="onSubmit"
            >
              {{ $t("Submit") }}
            </b-button>

            <b-button
              type="button"
              variant="danger"
              class="buttonCancel"
              style="
                margin-right: 15px;
                margin-top: 10px;
                background: white;
                color: black;
                border-color: white;
                font-weight: 400;
              "
              @click="onCancel"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
    <div style="border: 1px solid #DADCE0 ;">
      <div
        class="types"
      >
        <div
          :class="{ 'selected-filter': selectedFilters.includes('Vacation') }"
          style="
            font-weight: bold;
            background-color: #FF6666;
            color: white;
            text-align: center;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          "
          @click="setFilter('Vacation')"
        >
          {{ $t("Vacation") }}
        </div>
        <div
          :class="{ 'selected-filter': selectedFilters.includes('SickLeave') }"
          style="
            font-weight: bold;
            background-color: #BFC6FF;
            color: #131C6D;
            text-align: center;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('SickLeave')"
        >
          {{ $t("AddNew19") }}
        </div>
        <div
          :class="{ 'selected-filter': selectedFilters.includes('SpecialLeave') }"
          style="
            font-weight: bold;
            background-color: #FFDDDD;
            color: #721818;
            text-align: center;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('SpecialLeave')"
        >
          {{ $t("AddNew22") }}
        </div>
        <div
          :class="{ 'selected-filter': selectedFilters.includes('AccidentLeave') }"
          style="
            font-weight: bold;
            background-color: #BDFFDB;
            color: #2C5A41;
            text-align: center;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('AccidentLeave')"
        >
          {{ $t("AddNew20") }}
        </div>
        <div
          :class="{ 'selected-filter': selectedFilters.includes('Education') }"
          style="
            font-weight: bold;
            background-color: rgb(255, 235, 220);
            color: #684D08;
            text-align: center;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('Education')"
        >
          {{ $t("AddNew21") }}
        </div>
        <div
          :class="{ 'selected-filter': selectedFilters.includes('Maternity') }"
          style="
            font-weight: bold;
            background-color: violet;
            color: white;
            text-align: center;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('Maternity')"
        >
          {{ $t("AddNew23") }}
        </div>
        <div
          :class="{ 'selected-filter': selectedFilters.includes('Appointment') }"
          style="
            font-weight: bold;
            background-color: #FFEBB7;
            color: #684D08;
            text-align: center;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('Appointment')"
        >
          {{ $t("AddNew24") }}
        </div>
        <div
          :class="{ 'selected-filter': selectedFilters.includes('DeathCase') }"
          style="
            font-weight: bold;
            background-color: #6438E0;
            color: white;
            text-align: center;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('DeathCase')"
        >
          {{ $t("AddNew46") }}
        </div>
      </div>
      <div style="display: flex; margin: 15px;border: 1px solid #DADCE0; border-radius: 6px;">

        <div class="datesInfo">

          <b-form-group
            id="input-group-9"
            label-for="input-9"
            style="width:100%"
          >
            <date-picker
              v-model="selectedDate"
              value-type="format"
              class="form-controll"
              format="YYYY-MM-DD"
              type="date"
              :open="true"
              :placeholder="$t('SelectD')"
            />
          </b-form-group>
          <pre /><pre /><pre /><pre /><pre /><pre /><pre /><pre /><pre /><pre /><pre /><pre /><pre /><pre /><pre /><pre /><pre />
          <b-form-group class="today">
            <template #label>
              <strong><img src="../assets/images/calendarii.svg"><span style="color: #333333;padding-left: 5px;">{{ $t("Today") }}</span></strong>
            </template>
            <b-form-group
              v-for="employee in today"
              :key="employee"
              style="width:100%;padding-top: 10px;font-weight: bold;"
            >
              <p
                :style="{ color: getRandomColor() }"
                style="font-size: 12px;margin-bottom: 0px;"
              >


                <span
                  class="circle-before"
                  :style="{ background: getRandomColorSpan(),borderRadius: '50%' }"
                />
                {{ employee.fullNames }} {{ modify(employee.startDate) }} to {{ modify(employee.endDate) }}
              </p>
            </b-form-group>
            <p v-if="today.length == 0">
              {{ $t('NoEmployeeOnVacation') }}
            </p>
          </b-form-group>
          <b-form-group class="today">
            <template #label>
              <strong><img src="../assets/images/calendarii.svg"><span style="color: #333333;padding-left: 5px;">{{ $t("Date") }} : {{ titleOfCalendar }}</span></strong>
            </template>
            <b-form-group
              v-for="employee in dateofCalendar"
              :key="employee"
              style="width:100%;padding-top: 10px;font-weight: bold;"
            >
              <p
                :style="{ color: getRandomColor() }"
                style="font-size: 12px;margin-bottom: 0px;"
              >


                <span
                  class="circle-before"
                  :style="{ background: getRandomColorSpan(),borderRadius: '50%' }"
                />
                {{ employee.fullName }} from {{ modify(employee.startDate) }} to {{ modify(employee.endDate) }}
              </p>
            </b-form-group>
            <p v-if="dateofCalendar.length == 0">
              {{ $t('NoEmployeeOnLeave') }}
            </p>
          </b-form-group>
          <b-form-group class="today">
            <template #label>
              <strong><img src="../assets/images/calendarii.svg"><span style="color: #333333;padding-left: 5px;">{{ $t("Month") }}</span></strong>
            </template>
            <b-form-group
              v-for="employee in month"
              :key="employee"
              style="width:100%;padding-top: 10px;font-weight: bold;"
            >
              <p
                :style="{ color: getRandomColor() }"
                style="font-size: 12px;margin-bottom: 0px;"
              >


                <span
                  class="circle-before"
                  :style="{ background: getRandomColorSpan(),borderRadius: '50%' }"
                />
                {{ employee.fullName }} from {{ modify(employee.startDate) }} to {{ modify(employee.endDate) }}
              </p>
            </b-form-group>
            <p v-if="month.length == 0">
              {{ $t('NoVacationThisMonth') }}
            </p>
          </b-form-group>
        </div>
        <div
          class="custom-calendar"
          style="width: 100%;"
        >
          <FullCalendar :options="calendarOptions" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { client } from '../domainConfig';


export default {
  components: {
    FullCalendar,
    DatePicker,
  },
  data() {
    return {
      selectedDate: null,
      titleOfCalendar: '',
      nextDayClicked: false,
      nextMonthClicked: false,
      nextYearClicked: false,
      button: false,
      currentYear: moment().format('YYYY-MM-DD'),
      selectedFilters: [],
      currentFilter: 'All',
      checkStatus: false,
      isSingleDayEvent: false,
      saturdayWorkStatus: false,
      showModal: false,
      client: client.clientName,
      textOfCalendar: '',
      calendarOptions: {
        firstDay: 1,
        eventContent(info) {
          // const vacationType = info.event.extendedProps.description;
          // const eventBackgroundColor = info.event.backgroundColor;
          // if (vacation.vacationType === 'Vacation') {
          //   vacationType = this.$t('Vacation');
          //   eventBackgroundColor = 'rgb(245, 77, 77)';
          // }
          return {
            html: `
              <div>
          <div class="tooltip-container">
            <div class="tooltip-trigger">${info.event.title}</div>
            <div class="tooltip">${info.event.extendedProps.description}</div>
          </div>
        </div>
        <style>
        .tooltip-container {
            position: relative;
            display: inline-block;
          }
          .tooltip-trigger {
            color: rgb(104, 77, 8);
            font-size: 15px;
            font-weight: bold;
            white-space: normal;
            cursor: pointer;
          }
          .tooltip {
            visibility: hidden;
            width: 100px;
            background-color: #333;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 9999;
            bottom: 125%;
            left: 100%;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity 0.3s;
          }
          .tooltip-container:hover .tooltip {
            visibility: visible;
            opacity: 1;
          }
        </style>
      `,
          };
        },
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        views: {
          week: {
            buttonText: `${this.$t('Week')}`,
            type: 'dayGridWeek',
          },
          day: {
            buttonText: `${this.$t('Daily')}`,
            type: 'dayGridDay',
          },
          month: {
            buttonText: `${this.$t('Month')}`,
            type: 'dayGridMonth',
          },
          year: {
            buttonText: `${this.$t('Year')}`,
            type: 'dayGridYear',
          },
        },
        headerToolbar: {
          left: 'prev,next',
          right: 'week month year',
          center: 'title',
        },
        locale: 'en', // Set the locale to German (de)
        events: [], // Initialize with an empty array
      },
    };
  },
  computed: {
    ...mapGetters(['getEmployeeVacation', 'getIsLoading', 'getSaturdayWork']),
    today() {
      const today = moment().format('YYYY-MM-DD');
      return this.getEmployeeVacation.filter((vacation) => { return moment(vacation.startDate).format('YYYY-MM-DD') === today });
    },
    month() {
      return this.getEmployeeVacation.filter((vacation) => { return moment(vacation.startDate).format('YYYY-MM') === moment(this.titleOfCalendar).format('YYYY-MM') });
    },
    dateofCalendar() {
      return this.getEmployeeVacation.filter((vacation) => {
        const startDate = moment(vacation.startDate);
        const endDate = moment(vacation.endDate);
        const selectedDate = moment(this.titleOfCalendar);

        // Check if selected date is within the range of vacation start and end dates
        if (selectedDate.isBetween(startDate, endDate, null, '[]') || selectedDate.isSame(startDate, 'day')) {
          // If the selected date is within the range or matches the start date, return the employee
          return true;
        }

        return false;
      });
    },
    allVacations() {
      return this.getEmployeeVacation;
    },
  },
  watch: {
    getEmployeeVacation() {
      this.updateEvents();
    },
    currentYear(value) {
      this.selectedDate = value
    },
    titleOfCalendar(value) {
      this.selectedDate = value
    },
    selectedDate(value) {
      this.titleOfCalendar = value
      this.currentYear = value
      // const calendarTitleElement = document.querySelector('.mx-calendar-header-label');
      // calendarTitleElement.textContent = moment(this.titleOfCalendar).format('MMMM');
      this.employeeVacation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: value,
      })
    },
    // getSaturdayWork(value) {
    //   console.log(value.isActive)
    // },
    'getSaturdayWork.isActive': {
      handler(value) {
        // console.log(value)
        // this.editSaturdayWork({
        //   object: {
        //     clientName: this.client,
        //     isActive: value,
        //   },
        // })
        this.saturdayWorkStatus = value;
      },
    },
    // saturdayWorkStatus(value) {
    //   this.editSaturdayWork({
    //     object: {
    //       clientName: this.client,
    //       isActive: value,
    //     },
    //   })
    // },
  },
  mounted() {
    // this.changeLoadingtoTrue(false);
    this.selectedDate = this.currentYear
    this.loadSaturdayWork();

    this.employeeVacation({
      exactDate: null,
      startDate: null,
      endDate: null,
      year: moment(this.currentYear).format('YYYY-MM-01'),
    }).then(() => {
    });
    const prevButton = document.querySelector('.fc-prev-button');
    const nextButton = document.querySelector('.fc-next-button');
    const nextDay = document.querySelector('.fc-week-button');
    const nextMonth = document.querySelector('.fc-month-button');
    const nextYear = document.querySelector('.fc-year-button');
    const arrowElement = document.querySelector('.mx-icon-left');
    const arrowElementRight = document.querySelector('.mx-icon-right');


    if (prevButton && arrowElement) {
      arrowElement.addEventListener('click', () => {
        this.employeeVacation({
          exactDate: null,
          startDate: null,
          endDate: null,
          year: this.titleOfCalendar,
        }).then(() => {
          prevButton.click();
        }).catch((error) => {
          console.error('Error calling employeeVacation endpoint:', error);
        });
      });


      prevButton.addEventListener('click', () => {
        if (this.nextDayClicked) {
          this.handlePrevClickDay();
        } else if (this.nextMonthClicked) {
          this.handlePrevClickMonth();
        } else if (this.nextYearClicked) {
          this.handlePrevClickYear();
        } else {
          this.handlePrevClick();
        }
      });
    }

    if (nextButton && arrowElement) {
      arrowElementRight.addEventListener('click', () => {
        this.employeeVacation({
          exactDate: null,
          startDate: null,
          endDate: null,
          year: this.titleOfCalendar,
        }).then(() => {
          nextButton.click();
        }).catch((error) => {
          console.error('Error calling employeeVacation endpoint:', error);
        });
      });

      nextButton.addEventListener('click', () => {
        if (this.nextDayClicked) {
          this.handleNextClickDay();
        } else if (this.nextMonthClicked) {
          this.handleNextClickMonth();
        } else if (this.nextYearClicked) {
          this.handleNextClickYear();
        } else {
          this.handleNextClick();
        }
      });
    }

    if (nextDay) {
      nextDay.addEventListener('click', () => {
        const calendarTitleElement = document.querySelector('.fc-toolbar-title');
        this.nextDayClicked = true;
        this.nextMonthClicked = false;
        if (calendarTitleElement) {
          this.titleOfCalendar = calendarTitleElement.textContent.trim();
          const formattedDate = moment(this.titleOfCalendar, 'MMM D, YYYY').format('YYYY-MM-DD');


          this.titleOfCalendar = formattedDate;
        }
      });
    }
    if (nextMonth) {
      nextMonth.addEventListener('click', () => {
        this.nextMonthClicked = true;
        this.nextDayClicked = false;
        const calendarTitleElement = document.querySelector('.fc-toolbar-title');

        if (calendarTitleElement) {
          this.titleOfCalendar = calendarTitleElement.textContent.trim();
          const formattedDate = moment(this.titleOfCalendar, 'MMM D, YYYY').format('YYYY-MM-01');


          this.titleOfCalendar = formattedDate;
        }
      });
    }
    if (nextYear) {
      nextYear.addEventListener('click', () => {
        this.nextYearClicked = true;
        this.nextDayClicked = false;
        this.nextMonthClicked = false;
        const calendarTitleElement = document.querySelector('.fc-toolbar-title');

        if (calendarTitleElement) {
          this.titleOfCalendar = calendarTitleElement.textContent.trim();
          const formattedDate = moment(this.titleOfCalendar, 'YYYY').format('YYYY-01-01');


          this.titleOfCalendar = formattedDate;
        }
      });
    }
  },
  methods: {
    ...mapActions([
      'loadCalculation',
      'changeLoadingtoTrue',
      'loadSaturdayWork',
      'editSaturdayWork',
      'addSaturdayWork',
      'employeeVacation',
    ]),
    modify(value) {
      return moment(value).format('DD-MM')
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getRandomColorSpan() {
      const letters = '0123456789ABCDEF';
      let background = '#';
      for (let i = 0; i < 6; i++) {
        background += letters[Math.floor(Math.random() * 16)];
      }
      return background;
    },
    handlePrevClickDay() {
      const previousDay = moment(this.titleOfCalendar).subtract(7, 'days').format('YYYY-MM-DD');

      this.employeeVacation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousDay,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).subtract(7, 'days').format('YYYY-MM-DD');
    },
    handlePrevClickMonth() {
      const previousYear = moment(this.titleOfCalendar).subtract(1, 'months').format('YYYY-MM-01');

      this.employeeVacation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).subtract(1, 'months').format('YYYY-MM-01');
    },
    handlePrevClickYear() {
      const previousYear = moment(this.titleOfCalendar).subtract(1, 'year').format('YYYY-01-01');

      this.employeeVacation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).subtract(1, 'year').format('YYYY-01-01');
    },
    handleNextClickYear() {
      const previousYear = moment(this.titleOfCalendar).add(1, 'year').format('YYYY-01-01');

      this.employeeVacation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).add(1, 'year').format('YYYY-01-01');
    },
    handleNextClickMonth() {
      const previousYear = moment(this.titleOfCalendar).add(1, 'months').format('YYYY-MM-01');

      this.employeeVacation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).add(1, 'months').format('YYYY-MM-01');
    },
    handlePrevClick() {
      const previousYear = moment(this.currentYear).subtract(1, 'months').format('YYYY-MM-01');

      this.employeeVacation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.currentYear = moment(this.currentYear).subtract(1, 'months').format('YYYY-MM-01');
    },
    handleNextClick() {
      const previousYear = moment(this.currentYear).add(1, 'months').format('YYYY-MM-01');

      this.employeeVacation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.currentYear = moment(this.currentYear).add(1, 'months').format('YYYY-MM-01');
    },
    handleNextClickDay() {
      const previousYear = moment(this.titleOfCalendar).add(7, 'days').format('YYYY-MM-DD');

      this.employeeVacation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).add(7, 'days').format('YYYY-MM-DD');
    },
    setFilter(filter) {
      if (this.selectedFilters.includes(filter)) {
        const index = this.selectedFilters.indexOf(filter);
        this.selectedFilters.splice(index, 1);
      } else {
        this.selectedFilters.push(filter);
      }
      this.updateEvents();
    },
    changeSaturdayWork(value) {
      this.editSaturdayWork({
        object: {
          clientName: this.client,
          isActive: !value,
        },
      });
    },
    openModal() {
      this.showModal = true;
    },
    onCancel() {
      this.showModal = false;
    },
    onSubmit() {
      this.addSaturdayWork({
        object: {
          clientName: this.client,
          isActive: true,
        },
        successCallback: () => {
          this.loadSaturdayWork();
        },
      })
      this.showModal = false;
    },
    updateEvents() {
      const vacationData = this.getEmployeeVacation;
      let events = [];
      if (this.selectedFilters.length === 0) {
        events = vacationData.map((vacation) => {
          let vacationType = '';
          // const startDate = moment(vacation.startDate).format('DD-MM-YYYY');
          // const endDate = moment(vacation.endDate).format('DD-MM-YYYY');
          let eventBackgroundColor = '';

          // Set the vacationType and eventBackgroundColor based on the vacationType
          if (vacation.vacationType === 'Vacation') {
            vacationType = this.$t('Vacation');
            eventBackgroundColor = '#FF6666';
          } else if (vacation.vacationType === 'SickLeave') {
            vacationType = this.$t('AddNew19');
            eventBackgroundColor = 'rgb(191, 198, 255)'
          } else if (vacation.vacationType === 'SpecialLeave') {
            vacationType = this.$t('AddNew22');
            eventBackgroundColor = 'rgb(255, 221, 221)';
          } else if (vacation.vacationType === 'AccidentLeave') {
            vacationType = this.$t('AddNew20');
            eventBackgroundColor = 'rgb(189, 255, 219)';
          } else if (vacation.vacationType === 'Education') {
            vacationType = this.$t('AddNew21');
            eventBackgroundColor = 'rgb(255, 235, 220)';
          } else if (vacation.vacationType === 'Maternity') {
            vacationType = this.$t('AddNew23');
            eventBackgroundColor = 'violet';
          } else if (vacation.vacationType === 'Appointment') {
            vacationType = this.$t('AddNew24');
            eventBackgroundColor = 'rgb(255, 235, 183)';
          } else if (vacation.vacationType === 'DeathCase') {
            vacationType = this.$t('AddNew46');
            eventBackgroundColor = 'rgb(100, 56, 224)';
          }

          // Set the description with the vacationType
          const description = vacationType;
          const title = `${vacation.fullName}`;

          return {
            description,
            title,
            start: vacation.startDate,
            end: vacation.endDate,
            color: eventBackgroundColor,
          };
        });
      } else {
        events = vacationData.filter((vacation) => { return !this.selectedFilters.includes(vacation.vacationType) }).map((vacation) => {
          let vacationType = '';
          // const startDate = moment(vacation.startDate).format('DD-MM-YYYY');
          // const endDate = moment(vacation.endDate).format('DD-MM-YYYY');
          let eventBackgroundColor = '';

          // Set the vacationType and eventBackgroundColor based on the vacationType
          if (vacation.vacationType === 'Vacation') {
            vacationType = this.$t('Vacation');
            eventBackgroundColor = '#FF6666';
          } else if (vacation.vacationType === 'SickLeave') {
            vacationType = this.$t('AddNew19');
            eventBackgroundColor = 'rgb(191, 198, 255)'
          } else if (vacation.vacationType === 'SpecialLeave') {
            vacationType = this.$t('AddNew22');
            eventBackgroundColor = 'rgb(255, 221, 221)';
          } else if (vacation.vacationType === 'AccidentLeave') {
            vacationType = this.$t('AddNew20');
            eventBackgroundColor = 'rgb(189, 255, 219)';
          } else if (vacation.vacationType === 'Education') {
            vacationType = this.$t('AddNew21');
            eventBackgroundColor = 'rgb(255, 235, 220)';
          } else if (vacation.vacationType === 'Maternity') {
            vacationType = this.$t('AddNew23');
            eventBackgroundColor = 'violet';
          } else if (vacation.vacationType === 'Appointment') {
            vacationType = this.$t('AddNew24');
            eventBackgroundColor = 'rgb(255, 235, 183)';
          } else if (vacation.vacationType === 'DeathCase') {
            vacationType = this.$t('AddNew46');
            eventBackgroundColor = 'rgb(100, 56, 224)';
          }

          // Set the description with the vacationType
          const description = vacationType;
          const title = `${vacation.fullName}`;

          return {
            description,
            title,
            start: vacation.startDate,
            end: vacation.endDate,
            color: eventBackgroundColor,
          };
        });
      }
      this.calendarOptions.events = events;
    },
  },
};
</script>

<style>
.mx-icon-left{
  padding: 20px;
}
.mx-icon-right{
  padding: 20px;
}
.mx-btn-icon-double-left{
  display: none !important;
}
.mx-btn-icon-double-right{
  display: none !important;
}
.today {
  padding-top: 20px;
  padding-left: 10px;
}
.fc .fc-view-harness-active > .fc-view {
    inset: 0px;
    position: absolute;
    z-index: 0;
}
.custom-calendar .fc-daygrid-day {
    background-color: #fff;
    border: 0px solid #eee !important;
    width: calc(100% / 7);
    height: 80px;
}
.fc .fc-daygrid-day-frame {
    min-height: 100%;
    position: relative;
    border-right: 1px solid #DADCE0 !important ;
    border-bottom: 1px solid #DADCE0 !important ;
}
.fc-prev-button{
  background: white!important;
  color:#FF274F !important;
  border:1px solid #FF274F !important;

}
.fc-day-today{
  background: white !important;
}
.fc .fc-daygrid-day.fc-day-today {
    background-color: var(--fc-today-bg-color);
    border: 1px solid #FF274F !important;
}
.fc-next-button{
  background: white!important;
  color:#FF274F !important;
  border:1px solid #FF274F !important;

}
.fc-week-button{
  background: white!important;
  color:#FF274F !important;
  border:1px solid #FF274F !important;

}
.fc-month-button{
  background: white!important;
  color:#FF274F !important;
  border:1px solid #FF274F !important;
}
.fc-year-button{
  background: white!important;
  color:#FF274F !important;
  border:1px solid #FF274F !important;
}
.fc-col-header-cell{
  background: white !important;
  color: white !important;
}
.fc-col-header-cell-cushion{
  color: black !important;
}
.circle-before::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: inherit;
}
.mx-calendar {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 255px;
    padding: 6px 12px;
}
.mx-table{
  /* pointer-events: none; */
}
.mx-datepicker-popup {
    position: absolute !important;
    margin-top: 1px;
    margin-bottom: 1px;
    box-shadow: none;
    z-index: 0 !important;
    top: 260px !important;
    left: 175px !important;
    border: 0px solid black !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 2px;
    background: rgb(233, 179, 78);
    padding: 5px;
}
.mx-calendar-content .cell {
    cursor: pointer;
    color: black;
}
.mx-btn-text {
    border: 0;
    padding: 0 4px;
    text-align: left;
    line-height: inherit;
    color: black;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(191, 203, 217);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #242f6e;
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-calendar {
  max-width: 1300px;
  /* Custom styles for the FullCalendar component */
  /* background-color: #f2f2f2; */
  border: 0px solid #ccc;
  box-shadow: none;
  padding: 20px;
  margin: 0 18px;
  margin-top: 0px;
  margin-left: 0px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 2px;
    padding: 5px;
}
a {
  color: black;
  text-decoration: none;
}
.fc-day-header {
  text-decoration: none; /* Remove underline from day of the week headers */
}

.fc-day-number {
  text-decoration: none; /* Remove underline from numbers */
}

.fc-daygrid-day-events .fc-event-main {
  /* Set event box background color to light grey */
  padding: 0px !important;
  font-size: 14px !important
}

.CalendarSection {
  padding: 54px 133px 15px 158px;
  padding-top: 100px;
  background-color: transparent;
}

.custom-calendar .fc-daygrid-day {
  background-color: #fff;
  border: 1px solid #eee;
  width: calc(100% / 7); /* Divide the width evenly between 7 days */
  height: 80px;
}

.custom-calendar .fc-daygrid-day-number {
  color: #333;
  font-weight: bold;
  font-size: 14px;
}

  .custom-calendar .fc-event-main {
    /* background-color: lightgrey; */
    color: #fff;
    border-radius: 3px;
    font-size: 12px;
  }
  .mx-datepicker-popup {
    position: absolute;
    margin-top: 1px;
    margin-bottom: 1px;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 1;
}
  .types{
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    justify-content: center !important;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .form-controll {
    width: 90%;
    border: 0px solid #242f6e;
    margin-top: 23px;
    margin-left: 12px;
}
/* .custom-calendar .fc-event-main:hover {
    background-color: transparent;
    cursor: pointer;
  } */
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 2em;
  position: relative;
  margin-top: 35px;
}

.filter {
  font-weight: 400;
  color: black;
  text-align: center;
  flex: 1;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.selected-filter {
  position: relative;
}

.selected-filter::before {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: white;
}

.datesInfo {
  width: 17%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #DADCE0;
}

@media screen and (min-width: 1500px) and (max-width: 1750px) {
  .datesInfo {
      width: 20%;
    }
    .mx-calendar {
        box-sizing: border-box;
        width: 200px;
        padding: 0px 0px;
    }
    .mx-datepicker-popup {
      top: 280px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .CalendarSection{
      padding: 80px 25px 15px 100px;
    }
    .datesInfo {
      width: 23%;
    }
    .mx-datepicker-popup {
      position: absolute !important;
      margin-top: 1px;
      margin-bottom: 1px;
      box-shadow: none;
      z-index: 0 !important;
      top: 255px !important;
      left: 118px !important;
      border: 0px solid black !important;
    }
    .mx-calendar {
        box-sizing: border-box;
        width: 189px;
        padding: 0px 0px;
    }
    .fc-view-harness {
      height: 840px !important;
    }
}

@media screen and (max-width: 1041px) {
  .CalendarSection {
    padding: 100px 30px 15px 30px;
  }
}

  @media screen and (max-width: 1200px){
    .CalendarSection{
      padding: 60px 30px 15px 30px;
    }

    .fc-view-harness {
      height: 840px !important;
    }

    .datesInfo {
      width: 23%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #DADCE0;
    }

    /* .today {
      padding-top: 40px;
      padding-left: 10px;
    } */

    .types{
      width: 100%;
    }
    .mx-datepicker-popup {
      position: absolute !important;
      margin-top: 1px;
      margin-bottom: 1px;
      box-shadow: none;
      z-index: 0 !important;
      top: 245px !important;
      left: 55px !important;
      border: 0px solid black !important;
    }
    .mx-calendar {
        box-sizing: border-box;
        width: 152px;
        padding: 0px 0px;
    }
  }

  @media screen and (min-width: 1300px) and (max-width:1400px){
    .types{
      width: 82%;
    }
  }

  @media screen and (min-width:1400px) and (max-width: 1735px){
    .types{
      width: 84%;
    }
  }
  </style>
